$transition-normal: all 0.3s ease-in-out;
$c-white: #F5F5F5;

.tree-list,
.child-list {
	list-style: none;
	padding: 0;
	margin: 0;
	padding-bottom: 8px;
	margin-bottom: 8px;
	position: relative;

	li {
		margin-bottom: 14px;
		position: relative;
		transition: $transition-normal;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.list-checkbox {
		display: none;

		&:checked~.list-item-label {
			background-color: $c-white;
			border-radius: 0;

			&~.child-list {
				background-color: $c-white;
				max-height: 800px;
				padding-bottom: 8px;
				padding-top: 6px;
			}

			.arrow {
				transform: rotate(270deg);
			}
		}
	}

	.list-item-label {
		width: 100%;
		display: flex;
		justify-items: center;
		justify-content: space-between;
		padding: 12px 32px;
		text-align: left;
		color: #828282;
		font-size: 18px;
		cursor: default;

		&.active,
		&:hover {
			color: #404040;
		}

		.list-item-title {
			&::first-letter {
				text-transform: capitalize;
			}
		}

		>.arrow {
			width: 9px;
			height: 16px;
			display: block;
			transform: rotate(90deg);
			transition: $transition-normal;
		}
	}
}