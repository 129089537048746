@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/myriadPro/MyriadPro-Bold.eot');
  src: url('../fonts/myriadPro/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/myriadPro/MyriadPro-Bold.woff2') format('woff2'),
    url('../fonts/myriadPro/MyriadPro-Bold.woff') format('woff'),
    url('../fonts/myriadPro/MyriadPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/myriadPro/MyriadPro-Regular.eot');
  src: url('../fonts/myriadPro/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/myriadPro/MyriadPro-Regular.woff2') format('woff2'),
    url('../fonts/myriadPro/MyriadPro-Regular.woff') format('woff'),
    url('../fonts/myriadPro/MyriadPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
