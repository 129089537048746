@import '../../../assets/scss/vars';

.links-list {
	.service-wrapper {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			left: 16px;
			right: 16px;
			top: -8px;
			height: 1px;
			background-color: $c-gray;
		}
	}
}
