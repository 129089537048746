@import '../../assets/scss/vars';

.box-in-full-screen {
	@media screen and (max-width: 767px) {
		min-height: calc(100vh - 80px);
		max-height: calc(100vh - 80px);

		.box-iframe {
			padding-bottom: constant(safe-area-inset-bottom);
			padding-bottom: env(safe-area-inset-bottom);
		}
	}
}

.box-main-screen {
	> .title,
	.description {
		text-align: center;
	}

	> .title {
		font-size: 18px;
		line-height: 23px;
		margin-bottom: 10px;
	}

	.description {
		margin-bottom: 38px;
	}

	.content-to-scroll {
		overflow: auto;
		border-radius: 10px;
		mask-image: -webkit-radial-gradient(white, black);
		-webkit-mask-image: -webkit-radial-gradient(white, black);
	}

	iframe {
		width: 100%;
		height: auto;
		display: block;
		min-height: inherit;
		max-height: inherit;

		@media screen and (min-width: $w-lg) {
			min-height: 100vh;
		}
	}
}
