$f-main: 'Myriad Pro';

$c-white: #ffffff;
$c-white-2: #F5F5F5;

$c-black: #404040;
$c-black-1: #000000;
$c-black-2: #4F4F4F;

$c-orange: #F9C94A;
$c-orange-2: #FFFACE;
$c-orange-3: #FAB600;
$c-orange-4: #FEC01C;

$c-green: #4CAF50;
$c-green-2: #D2FFCE;

$c-gray: #D5D5D5;
$c-gray-2: #828282;
$c-gray-3: #EEEEEE;
$c-gray-4: #BDBDBD;

$c-red: #D32F2F;
$c-red-2: #FFCECE;

$br-r: 20px;
$br-r-md: 10px;
$br-r-md-2: 8px;
$br-r-sm: 5px;

$transition-fast: all 0.15s ease-in-out;
$transition-normal: all 0.3s ease-in-out;


$w-xl: 1200px;
$w-lg: 992px;
$w-md: 768px;
$w-sm: 575px;

$w-sm-s: 320px;