@import '../../../assets/scss/vars';

.line-user-info {
	align-items: center;
	padding-bottom: 16px;

	.wrap-img {
		min-width: 56px;
		width: 56px;
		height: 56px;
		background-color: $c-orange-3;
		background-size: cover;
		background-repeat: no-repeat;
		border: 1px solid $c-gray;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 18px;
		overflow: hidden;

		>img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.user-data {
		p {
			margin-bottom: 0;
			margin-top: 5px;
		}
	}

	.name-user {
		font-size: 20px;
		color: #4F4F4F;
		margin-top: 0;
	}
}