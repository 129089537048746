@import '../../../assets/scss/vars';

.positions-list {
	display: none;

	&.has-position {
		display: block;
	}

	.positions {
		padding: 0;
		margin: 0;
		list-style: none;

		.position-item {
			margin-bottom: 15px;
		}

		.position-name {
			display: block;
			position: relative;
			color: $c-gray-2;
			padding-left: 40px;
			transition: $transition-normal;

			&:before {
				content: '';
				position: absolute;
				left: 17px;
				top: 1px;
				display: block;
				width: 14px;
				height: 14px;
				border-radius: 50%;
				border: 1px solid $c-gray;
				transition: $transition-normal;
			}

			&:after {
				content: '';
				position: absolute;
				left: 21px;
				top: 2px;
				display: inline-block;
				padding: 3px 1.5px;
				border: solid $c-white;
				border-width: 0 2.5px 2.5px 0;
				transform: rotate(42deg);
				transition: $transition-normal;
				opacity: 0;
			}
		}
	}

	.trigger {
		display: none;

		&:checked+.position-name {
			color: $c-black-2;

			&:before {
				background-color: $c-orange-3;
				border: 1px solid $c-orange-3;
			}

			&:after {
				opacity: 1;
			}
		}
	}
}