.parent-list-item {
	.child-list {
		overflow-y: hidden;
		border-bottom: 0;
		padding-bottom: 0;
		max-height: 0;
		padding-left: 45px;
		transition: all 0.5s ease;

		li {
			&:last-child {
				margin-bottom: 8px;
			}
		}

		.list-checkbox {
			display: none;

			&:checked~.list-item-label {
				background-color: transparent;

				.child-list {
					background-color: transparent;
				}
			}
		}

		.list-item-label {
			padding: 6px 5px;
			font-size: 16px;
		}

		.child-list {
			padding-left: 20px;
			padding-top: 5px;
		}
	}
}