html {
  height: 100%;
  margin: 0;
  padding: 0;
  touch-action: none;
  position: relative;
  background-color: $c-white-2;

  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  box-sizing: unset;
}

body {
  width: 100%;
  font-family: $f-main;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: $c-black;

  padding-top: 0;
  padding-bottom: 0;

  @media screen and (max-width:991px) {
    position: fixed;
  }
}

h1,
h2,
h3,
h4,
h5,
.title {
  font-weight: bold;
  font-style: normal;
}

.title {
  >span {
    font-weight: normal;
  }
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.App {
  position: relative;
  display: flex;

  flex: 1 1;

  @media screen and (max-width:991px) {
    padding-top: 54px;
    flex-direction: column;

    &:before {
      content: '';
      position: fixed;
      left: 0;
      right: 0;
      display: block;
      border-radius: $br-r-md-2;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      height: 121px;
      top: 47px;
      background-color: $c-orange;

      @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
        height: 141px;
      }
    }
  }



}

main {
  position: relative;
  flex: 1 1;
  display: flex;
  flex-direction: column;

  >section {
    width: 100%;
  }
}

.container {
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (min-width:$w-lg) {
    padding: 15px 15px;
  }

  @media screen and (min-width:$w-xl) {
    padding: 32px 32px;
  }
}

.line {
  display: flex;
}

.line-reverse {
  flex-direction: row-reverse;
}

.column {
  display: flex;
  flex-direction: column;
}


.btn {
  display: inline-block;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  margin: 0;
  text-align: center;
  transition: $transition-normal;
  padding: 8px 8px;
  border-radius: $br-r;
  font-size: 14px;
  line-height: 16px;
  color: $c-black;

  &:hover,
  &:focus,
  &.active {
    text-decoration: none;
    outline: none;
  }
}

.btn-orange {
  background-color: $c-orange-3;

  &:hover,
  &.active {
    color: $c-black;
    background-color: lighten($c-orange-3, 10%);
  }
}