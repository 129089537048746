@import '../../assets/scss/vars';

.msgs-item {
	border-bottom: 1px solid #e7e7e7;

	.msg-btn {
		display: grid;
		grid-template-columns: 9.5fr 1fr;
		column-gap: 2.5vw;
		padding: 0;
		border: 0;
		background-color: $c-white;
		color: $c-gray-2;
		text-align: start;

		&:focus {
			outline: none;
		}

		&.unchecked {
			color: $c-black-1;
		}
	}

	.msg-content {
		padding: 4.45vw;
		padding-right: 0;

		.date-time {
			color: $c-gray-2;
			margin-bottom: 1vh;
		}
	}

	.msg-title {
		font-weight: 700;
		margin-bottom: 1vh;
	}

	.open-arrow {
		display: flex;
	}

	.img-arrow {
		align-self: center;
	}
}
