$c-gray: #404040;

.child-list-item {
	.active {
		color: $c-gray;
	}

	.list-item-label:hover {
		color:$c-gray;
	}

	.list-radio {
		display: none;

		&:checked~.list-item-label {
			color: $c-gray;
		}
	}
}