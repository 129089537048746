@import "../../assets/scss/vars";

.App {
	&.App-error {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;

		@media screen and (max-width:991px) {
			&:before {
				top: 0;
				height: 168px;

				@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
					height: 188px;
				}
			}
		}
	}

	.box-plug-critical-error {
		&.column {
			justify-content: flex-start;
		}
	}

	.plug {
		width: 100%;

		&.inbox {
			min-height: initial;
		}
	}

	.btn-support {
		margin-top: 1.4vh;
		background-color: $c-orange-4;
		border: none;
		border-radius: 20px;
		font-size: 1.15em;
		line-height: 1.25;
		padding: 1.775vh 0;
	}

	.btn-title {
		padding: 0 18.1vw;
	}
}