@import '../../assets/scss/vars';

header {
	@media screen and (max-width:991px) {
		background-color: $c-orange;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: 1;
		padding-top: 20px;
		padding-top: constant(safe-area-inset-top);
		padding-top: env(safe-area-inset-top);
		min-height: 50px;
	}

	>.container {
		@media screen and (min-width:$w-lg) {
			display: none;
		}
	}
}

.line-head {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	align-items: center;
	padding: 16px 4px 14px;

	>button {
		padding: 0;
		margin: 0;
		border: 0;
		outline: none;
		background: none;
		cursor: pointer;
	}

	.btn-toggle-nav {
		justify-self: start;
		flex-direction: column;

		span {
			width: 18px;
			height: 2px;
			display: block;
			background-color: $c-black;
			margin-bottom: 3px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.title-active-module {
	font-size: 18px;
	line-height: normal;
	padding: 0 3px;

	&::first-letter {
		text-transform: capitalize;
	}
}