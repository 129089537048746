@import '../../../assets/scss/vars';

.widget {
	border: 1px solid #EEEEEE;
	padding: 16px;
	font-size: 1.15em;
	line-height: 1.2;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 3px;
	}

	&::first-letter {
		text-transform: capitalize;
	}

	&.box-module-screen {
		position: static;
	}

	.widget-title {
		text-align: center;
		font-size: 1.25em;
	}
	
	.widget-btn {
		width: 100%;
		background-color: $c-orange-4;
		font-size: inherit;
		line-height: inherit;

		&::first-letter {
			text-transform: capitalize;
		}

		&:active {
			background-color: $c-gray-3;
		}
	}

	.widget-loading {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 3.2vh 0 1.4vh;
	}

	.widget-info {
		margin: 3.2vh 0 4.4vh;
		color: $c-gray-2;
		text-align: center;

		&.no-button {
			margin-bottom: 1.4vh;
		}

		&::first-letter {
			text-transform: capitalize;
		}
	}

	.widget-content {
		margin: 1.4vh 0 2.8vh;
	}
}