@import '../../assets/scss/vars';

.section-aside-nav {
	@media screen and (max-width: 991px) {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 5;
		background-color: rgba(44, 44, 44, 0.9);
		pointer-events: none;
		opacity: 0;
		transition: $transition-normal;
		padding-top: 20px;
		padding-top: constant(safe-area-inset-top);
		padding-top: env(safe-area-inset-top);
	}

	@media screen and (min-width: $w-lg) {
		height: 100%;
		padding: 15px 0;
		min-width: 304px;
	}

	@media screen and (min-width: $w-xl) {
		padding: 32px 0;
	}

	&.active {
		opacity: 1;

		.box-aside-nav {
			transform: translateX(0);
		}
	}

	.box-aside-nav {
		background-color: $c-white;
		z-index: 6;
		display: flex;
		flex-direction: column;

		@media screen and (max-width: 991px) {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			min-width: 304px;
			width: calc(100% - 56px);
			transform: translateX(-150%);

			transition: $transition-normal;
			pointer-events: auto;
			padding-top: constant(safe-area-inset-top);
			padding-top: env(safe-area-inset-top);
		}

		@media screen and (min-width: $w-lg) {
			height: 100%;
			border-radius: 0px 10px 10px 0px;
			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
			border: 1px solid $c-gray-3;
		}

		@media screen and(max-width:$w-sm-s) {
			min-width: 270px;
		}

		>.btn-toggle-aside-nav {
			position: absolute;
			top: 0;
			left: 100%;
			border: 0;
			padding: 0;
			margin: 0;
			outline: none;
			background-color: transparent;
			width: 56px;
			height: 56px;
			display: block;
			top: constant(safe-area-inset-top);
			top: env(safe-area-inset-top);

			@media screen and (min-width: $w-lg) {
				display: none;
			}

			@media screen and(max-width:$w-sm-s) {
				width: 50px;
				height: 50px;
			}

			span {
				background-color: $c-white;
				display: block;
				height: 3px;
				width: 30px;
				position: absolute;

				@media screen and(max-width:$w-sm-s) {
					height: 2px;
					width: 28px;
				}

				&:first-child {
					transform: translate(40%) rotate(-45deg);
				}

				&:last-child {
					transform: translate(40%) rotate(45deg);
				}
			}
		}
	}

	.aside-nav-content {
		height: 100%;
		overflow-y: auto;


		.aside-head-content {
			border-bottom: 1px solid $c-gray-4;
			margin: 8px 16px;
		}
	}
}