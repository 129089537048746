@import '../../assets/scss/vars';

.notification-center {
	.box-content {
		min-height: auto;
	}

	.messages-list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.load-content {
		display: flex;
		justify-content: center;
	}

	.load-more {
		padding: 2.935vh 0;
		color: $c-gray-2;
		font-size: 0.875em;
		text-transform: capitalize;
	}

	.load-icon {
		margin-right: 1.85vw;
		animation: rotate 1s infinite linear;
	}

	.load-text {
		padding-top: 0.25vh;
	}
}