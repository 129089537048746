@import '../../assets/scss/vars';

.box-module-screen {
	position: relative;
	background: $c-white;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	border-radius: $br-r-md;
	margin-top: 11px;

	&.box-plug {
		border: 1px solid $c-gray-3;
		padding: 10px;
		align-items: center;
		justify-content: center;
	}

	@media screen and (min-width: $w-lg) {
		margin-top: 0px;
	}

	.title-plug-app {
		font-weight: normal;
		font-size: 16px;
		text-align: center;
		text-transform: uppercase;
	}

	.plug-img {
		display: block;
		margin-bottom: 37px;
		max-width: 170px;
		width: 100%;
	}
}