.btn-notification-center {
	position: relative;
	justify-self: end;

	&:focus {
		outline: none;
	}

	&.unread-marker:after {
		content: '';
		position: absolute;
		top: -2px;
		right: -4px;
		width: 12px;
		height: 12px;
		background-color: #FF3737;
		border-radius: 69px;
	}
}